import React from 'react';

function Settings() {
  return (
    <div>
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">Settings</h2>
      <p className="text-gray-600">This is where you would manage your application settings.</p>
    </div>
  );
}

export default Settings;
