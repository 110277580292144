import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { 
  Users, 
  UserPlus, 
  Wallet, 
  CreditCard, 
  BarChart, 
  Eye,
  ArrowRight
} from 'lucide-react';
import AdminUsersList from './AdminUsersList';

function Dashboard() {
  const [stats, setStats] = useState({
    totalUsers: 0,
    newUsersToday: 0,
    activeUsers: 0,
    totalWalletAmount: 0
  });
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      
      // Fetch statistics
      const statsResponse = await axios.get(`${API_BASE_URL}/users/stats`);
      setStats(statsResponse.data);
      
      // Fetch recent transactions
      const transactionsResponse = await axios.get(`${API_BASE_URL}/payments`, {
        params: { limit: 5, sortBy: 'createdAt', sortOrder: 'desc' }
      });
      
      // Check if the response has the new structure or old structure
      if (transactionsResponse.data.payments) {
        setRecentTransactions(transactionsResponse.data.payments);
      } else {
        // Fallback for old API structure
        setRecentTransactions(transactionsResponse.data.slice(0, 5));
      }
      
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please try again.');
      
      // Set mock data if API fails
      setStats({
        totalUsers: 125,
        newUsersToday: 3,
        activeUsers: 87,
        totalWalletAmount: 12580.75
      });
      
      setRecentTransactions([
        { _id: '1', userId: '101', amount: 150.00, status: 'succeeded', createdAt: new Date().toISOString() },
        { _id: '2', userId: '102', amount: 75.50, status: 'pending', createdAt: new Date(Date.now() - 3600000).toISOString() }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    return `R ${parseFloat(amount).toFixed(2)}`;
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'succeeded': return 'bg-green-100 text-green-800';
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'failed': return 'bg-red-100 text-red-800';
      case 'cancelled': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex space-x-3 slide-in-right">
          <Link to="/users" className="btn btn-primary rounded-lg px-4 py-2 flex items-center gap-2 hover-scale">
            <Users size={18} /> Manage Users
          </Link>
          <Link to="/transactions" className="btn btn-outline-primary rounded-lg px-4 py-2 flex items-center gap-2 hover-scale">
            <CreditCard size={18} /> View Transactions
          </Link>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 slide-in-up">
        <div className="card hover-shadow p-6 flex items-center">
          <div className="rounded-full bg-primary-lightest p-4 mr-4">
            <Users className="text-primary h-6 w-6" />
          </div>
          <div>
            <h3 className="text-gray-500 text-sm font-medium">Total Users</h3>
            <p className="text-2xl font-bold">{stats.totalUsers}</p>
          </div>
        </div>
        
        <div className="card hover-shadow p-6 flex items-center">
          <div className="rounded-full bg-primary-lightest p-4 mr-4">
            <UserPlus className="text-primary h-6 w-6" />
          </div>
          <div>
            <h3 className="text-gray-500 text-sm font-medium">New Users Today</h3>
            <p className="text-2xl font-bold">{stats.newUsersToday}</p>
          </div>
        </div>
        
        <div className="card hover-shadow p-6 flex items-center">
          <div className="rounded-full bg-primary-lightest p-4 mr-4">
            <Wallet className="text-primary h-6 w-6" />
          </div>
          <div>
            <h3 className="text-gray-500 text-sm font-medium">Total Wallet Amount</h3>
            <p className="text-2xl font-bold">{formatCurrency(stats.totalWalletAmount)}</p>
          </div>
        </div>
        
        <div className="card hover-shadow p-6 flex items-center">
          <div className="rounded-full bg-primary-lightest p-4 mr-4">
            <BarChart className="text-primary h-6 w-6" />
          </div>
          <div>
            <h3 className="text-gray-500 text-sm font-medium">Active Users</h3>
            <p className="text-2xl font-bold">{stats.activeUsers}</p>
          </div>
        </div>
      </div>

      {/* Recent Transactions */}
      <div className="card overflow-hidden fade-in">
        <div className="card-header flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">Recent Transactions</h2>
          <Link to="/transactions" className="text-primary hover:text-primary-dark text-sm font-medium flex items-center gap-1 hover-scale">
            View All <ArrowRight size={16} />
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {recentTransactions.length > 0 ? (
                recentTransactions.map((transaction) => (
                  <tr key={transaction._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{transaction._id.substring(0, 8)}...</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{transaction.userName || 'Unknown User'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatCurrency(transaction.amount)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(transaction.status)}`}>
                        {transaction.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(transaction.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <Link to={`/transactions?id=${transaction._id}`} className="text-primary hover:text-primary-dark flex items-center gap-1 hover-scale inline-flex">
                        <Eye size={16} /> View
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-sm text-gray-500">
                    {loading ? 'Loading transactions...' : 'No recent transactions found'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Recent Users */}
      <div className="card overflow-hidden fade-in">
        <div className="card-header flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">Recent Users</h2>
          <Link to="/users" className="text-primary hover:text-primary-dark text-sm font-medium flex items-center gap-1 hover-scale">
            View All <ArrowRight size={16} />
          </Link>
        </div>
        <AdminUsersList limit={5} compact={true} />
      </div>
    </div>
  );
}

export default Dashboard;
