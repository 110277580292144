import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PaymentResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const isSuccess = location.pathname.includes('/success');

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className={`mt-6 text-center text-3xl font-extrabold ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
            {isSuccess ? 'Payment Successful!' : 'Payment Failed'}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {isSuccess
              ? 'Your payment has been processed successfully.'
              : 'There was an error processing your payment. Please try again.'}
          </p>
          <p className="mt-2 text-center text-sm text-gray-500">
            Redirecting you back to the home page...
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentResult;
