import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Logo component for consistent branding across the application
 * @param {Object} props - Component props
 * @param {boolean} props.compact - Whether to show the compact version of the logo
 * @param {boolean} props.withBackground - Whether to add a white background behind the logo
 * @param {boolean} props.linkToHome - Whether the logo should link to the home page
 * @param {string} props.className - Additional CSS classes
 */
function Logo({ compact = false, withBackground = true, linkToHome = true, className = '' }) {
  const logoContent = (
    <div 
      className={`${withBackground ? 'bg-white p-2 rounded-md shadow-sm' : ''} flex items-center ${className}`}
    >
      <img 
        src={compact ? '/logo_gm_compact.png' : '/logo_gm.png'} 
        alt="Golden Mile" 
        className={`${compact ? 'h-8' : 'h-10'} w-auto`} 
      />
    </div>
  );

  if (linkToHome) {
    return (
      <Link to="/" className="flex items-center">
        {logoContent}
      </Link>
    );
  }

  return logoContent;
}

export default Logo;
