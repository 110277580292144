import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import YocoCheckout from './YocoCheckout';

function ClientScan() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('user_id');
    console.log('Extracted user_id from URL:', userId);
    
    if (userId) {
      fetchUser(userId);
    } else {
      setError('No user ID provided in URL');
      setLoading(false);
    }
  }, [location]);

  const fetchUser = async (userId) => {
    try {
      setLoading(true);
      console.log('Fetching user from:', `${API_BASE_URL}/users/${userId}`);
      
      const response = await axios.get(`${API_BASE_URL}/users/${userId}`);
      console.log('User data received:', response.data);
      
      setUser(response.data);
      setError(null);
    } catch (error) {
      console.error('Error details:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          method: error.config?.method
        }
      });

      let errorMessage = 'An error occurred while fetching user data.';
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = 'User not found. Please check the QR code and try again.';
        } else {
          errorMessage = `Server error: ${error.response.status} ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = 'Network error. Please check your internet connection and try again.';
      }

      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="text-center text-gray-600">Loading user data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative max-w-md w-full">
          <h4 className="font-bold mb-2">Error</h4>
          <p className="mb-2">{error}</p>
          <hr className="my-2 border-red-300" />
          <p className="text-sm">
            If the problem persists, please contact support or try scanning the QR code again.
          </p>
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="bg-yellow-50 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative max-w-md w-full">
          No user data available. Please try scanning the QR code again.
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="text-2xl font-bold text-gray-900 text-center mb-6">
              Make Payment to {user.name} {user.surname}
            </h2>
            
            <div className="text-center mb-6 text-gray-600">
              <p className="mb-1"><span className="font-semibold">Email:</span> {user.email}</p>
              <p className="mb-1"><span className="font-semibold">Mobile:</span> {user.mobileNumber}</p>
              {user.company && (
                <p className="mb-1"><span className="font-semibold">Company:</span> {user.company}</p>
              )}
            </div>

            <YocoCheckout user={user} onError={handleError} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientScan;