import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { FaSearch, FaFilter, FaSort, FaSortUp, FaSortDown, FaDownload, FaUserPlus } from 'react-icons/fa';

function UsersList() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [companies, setCompanies] = useState(new Set());
    
    // Advanced filters
    const [walletFilter, setWalletFilter] = useState('all');
    const [dateFilter, setDateFilter] = useState('all');
    const [showFilters, setShowFilters] = useState(false);
    // We use allUsers for filtering operations
    const [allUsers, setAllUsers] = useState([]);
    
    // Function to filter users based on advanced criteria
    const filterUsers = () => {
        if (allUsers.length === 0) return;
        // Additional filtering logic can be implemented here
    };

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            // Get all users for client-side filtering
            const allUsersResponse = await axios.get(`${API_BASE_URL}/users/all`);
            setAllUsers(allUsersResponse.data);
            
            // Get paginated users for display
            const response = await axios.get(`${API_BASE_URL}/users`, {
                params: {
                    page,
                    limit: 10,
                    search,
                    company,
                    sortBy,
                    sortOrder,
                    walletFilter,
                    dateFilter
                }
            });
            setUsers(response.data.users);
            setTotalPages(response.data.totalPages);
            
            // Update companies list for filter
            const allCompanies = new Set(allUsersResponse.data.map(user => user.company).filter(Boolean));
            setCompanies(allCompanies);
            
            setError(null);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users. Please try again.');
            
            // If we can't get data from the server, use mock data
            const mockUsers = [
                { _id: '1', name: 'John', surname: 'Doe', company: 'ABC Corp', email: 'john@example.com', walletAmount: 150.5, additionalInfo: 'VIP Customer', createdAt: new Date().toISOString() },
                { _id: '2', name: 'Jane', surname: 'Smith', company: 'XYZ Ltd', email: 'jane@example.com', walletAmount: 75.25, additionalInfo: 'New Customer', createdAt: new Date(Date.now() - 86400000).toISOString() }
            ];
            setUsers(mockUsers);
            setAllUsers(mockUsers);
            setTotalPages(1);
            setCompanies(new Set(['ABC Corp', 'XYZ Ltd']));
        } finally {
            setLoading(false);
        }
    }, [page, search, company, sortBy, sortOrder, walletFilter, dateFilter]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleSort = (field) => {
        if (sortBy === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(field);
            setSortOrder('asc');
        }
    };
    
    const getSortIcon = (columnName) => {
        if (sortBy !== columnName) return <FaSort className="inline ml-1" />;
        return sortOrder === 'asc' ? 
            <FaSortUp className="inline ml-1 text-primary-color" /> : 
            <FaSortDown className="inline ml-1 text-primary-color" />;
    };
    
    const resetFilters = () => {
        setSearch('');
        setCompany('');
        setWalletFilter('all');
        setDateFilter('all');
        setSortBy('name');
        setSortOrder('asc');
    };

    const exportUsers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/users/export`);
            const users = response.data;

            // Create worksheet
            const ws = XLSX.utils.json_to_sheet(users.map(user => ({
                Name: user.name,
                Surname: user.surname,
                Email: user.email,
                Company: user.company,
                'Mobile Number': user.mobileNumber,
                'Additional Info': user.additionalInfo,
                'Wallet Amount': user.walletAmount,
                'Tracking Number': user.trackingNumber
            })));

            // Create workbook
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Users');

            // Generate Excel file
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
            // Save file
            saveAs(data, 'users.xlsx');
        } catch (error) {
            console.error('Error exporting users:', error);
            setError('Failed to export users. Please try again.');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-bold text-gray-800">Users List</h2>
                <div className="flex space-x-2">
                    <button
                        onClick={exportUsers}
                        className="btn btn-outline-primary rounded-lg px-4 py-2 flex items-center gap-2"
                    >
                        <FaDownload /> Export to Excel
                    </button>
                    <Link
                        to="/users/new"
                        className="btn btn-primary rounded-lg px-4 py-2 flex items-center gap-2"
                    >
                        <FaUserPlus /> Add New User
                    </Link>
                </div>
            </div>

            <div className="mb-6">
                {/* Search and Basic Filters */}
                <div className="flex flex-wrap gap-4 mb-4">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FaSearch className="text-gray-400" />
                        </div>
                        <input
                            type="text"
                            placeholder="Search users..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="pl-10 p-2 border rounded-lg w-64"
                        />
                    </div>
                    
                    <div className="relative">
                        <select
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            className="p-2 border rounded-lg w-64 appearance-none pl-3 pr-10"
                        >
                            <option value="">All Companies</option>
                            {[...companies].map((comp) => (
                                <option key={comp} value={comp}>{comp}</option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <FaFilter className="text-gray-400" />
                        </div>
                    </div>
                    
                    <button 
                        onClick={() => setShowFilters(!showFilters)}
                        className="btn btn-outline-primary rounded-lg px-4 py-2 flex items-center gap-2"
                    >
                        <FaFilter /> {showFilters ? 'Hide Filters' : 'Show More Filters'}
                    </button>
                    
                    <button 
                        onClick={resetFilters}
                        className="btn btn-outline-primary rounded-lg px-4 py-2 flex items-center gap-2"
                    >
                        Reset Filters
                    </button>
                </div>
                
                {/* Advanced Filters */}
                {showFilters && (
                    <div className="bg-white p-4 rounded-lg shadow mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Wallet Amount</label>
                            <div className="relative">
                                <select
                                    value={walletFilter}
                                    onChange={(e) => setWalletFilter(e.target.value)}
                                    className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:border-primary-color focus:ring focus:ring-primary-lightest focus:ring-opacity-50 sm:text-sm rounded-lg"
                                >
                                    <option value="all">All Amounts</option>
                                    <option value="zero">No Balance (R0)</option>
                                    <option value="low">Low Balance (R0.01 - R50)</option>
                                    <option value="medium">Medium Balance (R50.01 - R200)</option>
                                    <option value="high">High Balance (R200+)</option>
                                </select>
                                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                    <FaFilter className="text-gray-400" />
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Registration Date</label>
                            <div className="relative">
                                <select
                                    value={dateFilter}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                    className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:border-primary-color focus:ring focus:ring-primary-lightest focus:ring-opacity-50 sm:text-sm rounded-lg"
                                >
                                    <option value="all">All Time</option>
                                    <option value="today">Today</option>
                                    <option value="week">Last 7 Days</option>
                                    <option value="month">Last 30 Days</option>
                                    <option value="year">This Year</option>
                                </select>
                                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                    <FaFilter className="text-gray-400" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="text-sm text-gray-600">
                    Showing {users.length} users {search && `matching "${search}"`} 
                    {company && ` from company "${company}"`}
                    {walletFilter !== 'all' && ` with ${walletFilter} wallet balance`}
                    {dateFilter !== 'all' && ` registered ${dateFilter === 'today' ? 'today' : 
                                            dateFilter === 'week' ? 'in the last 7 days' : 
                                            dateFilter === 'month' ? 'in the last 30 days' : 'this year'}`}
                </div>
            </div>

            {loading ? (
                <div className="text-center">Loading users...</div>
            ) : error ? (
                <div className="text-red-600 text-center">{error}</div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow rounded-lg overflow-hidden">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th onClick={() => handleSort('name')} className="cursor-pointer py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex items-center">Name {getSortIcon('name')}</div>
                                    </th>
                                    <th onClick={() => handleSort('company')} className="cursor-pointer py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex items-center">Company {getSortIcon('company')}</div>
                                    </th>
                                    <th onClick={() => handleSort('email')} className="cursor-pointer py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex items-center">Email {getSortIcon('email')}</div>
                                    </th>
                                    <th onClick={() => handleSort('walletAmount')} className="cursor-pointer py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex items-center">Wallet Amount {getSortIcon('walletAmount')}</div>
                                    </th>
                                    <th onClick={() => handleSort('createdAt')} className="cursor-pointer py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div className="flex items-center">Registration Date {getSortIcon('createdAt')}</div>
                                    </th>
                                    <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <tr key={user._id} className="border-t hover:bg-gray-50">
                                        <td className="py-3 px-4">
                                            {user.name} {user.surname}
                                        </td>
                                        <td className="py-3 px-4">{user.company}</td>
                                        <td className="py-3 px-4">{user.email}</td>
                                        <td className="py-3 px-4">
                                            R {user.walletAmount != null ? user.walletAmount.toFixed(2) : 'N/A'}
                                        </td>
                                        <td className="py-3 px-4">
                                            {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'N/A'}
                                        </td>
                                        <td className="py-3 px-4">
                                            <Link
                                                to={`/users/${user._id}`}
                                                className="text-primary-color hover:text-primary-dark mr-2"
                                            >
                                                View
                                            </Link>
                                            <Link
                                                to={`/users/${user._id}/edit`}
                                                className="text-primary-color hover:text-primary-dark"
                                            >
                                                Edit
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-6 flex justify-between items-center bg-white p-4 rounded-lg shadow">
                        <div className="text-sm text-gray-600">
                            Page {page} of {totalPages}
                        </div>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setPage(Math.max(1, page - 1))}
                                disabled={page === 1}
                                className="btn btn-outline-primary rounded-lg px-4 py-2 disabled:opacity-50"
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => setPage(Math.min(totalPages, page + 1))}
                                disabled={page === totalPages}
                                className="btn btn-outline-primary rounded-lg px-4 py-2 disabled:opacity-50"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default UsersList;
