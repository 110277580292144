// API URLs based on environment
const isDevelopment = process.env.NODE_ENV === 'development';

// Default to development URLs if environment variables are not set
const DEFAULT_DEV_API_URL = 'http://localhost:5001/api';
const DEFAULT_DEV_FRONTEND_URL = 'http://localhost:3000';

const DEFAULT_PROD_API_URL = 'https://qcentral.co.za/api';
const DEFAULT_PROD_FRONTEND_URL = 'https://qcentral.co.za';

// Use environment variables if available, otherwise use defaults based on environment
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 
    (isDevelopment ? DEFAULT_DEV_API_URL : DEFAULT_PROD_API_URL);

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 
    (isDevelopment ? DEFAULT_DEV_FRONTEND_URL : DEFAULT_PROD_FRONTEND_URL);

// Export environment flag for conditional logic elsewhere in the app
export const IS_DEVELOPMENT = isDevelopment;