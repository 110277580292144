import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import YocoCheckout from './YocoCheckout';
import '../styles/theme.css';

function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserAndTransactions = async () => {
      try {
        setLoading(true);
        console.log('Fetching user details for ID:', userId);
        
        // Fetch user details
        const userResponse = await axios.get(`${API_BASE_URL}/users/${userId}`);
        console.log('User API response:', userResponse.data);
        setUser(userResponse.data);
        
        // Fetch user transactions
        const transactionsResponse = await axios.get(`${API_BASE_URL}/payments?userId=${userId}`);
        console.log('Transactions API response:', transactionsResponse.data);
        
        // Handle different API response formats
        let transactionsData = [];
        if (transactionsResponse.data && transactionsResponse.data.payments) {
          // New API format with payments property
          transactionsData = transactionsResponse.data.payments;
        } else if (Array.isArray(transactionsResponse.data)) {
          // Old API format (direct array)
          transactionsData = transactionsResponse.data;
        } else {
          console.error('Unexpected API response format:', transactionsResponse.data);
        }
        
        setTransactions(transactionsData);
        
        // Calculate wallet balance from successful transactions
        const successfulTransactions = transactionsData.filter(tx => tx.status === 'succeeded');
        const totalBalance = successfulTransactions.reduce((sum, tx) => sum + parseFloat(tx.amount), 0);
        setWalletBalance(totalBalance);
        
        setLoading(false);
        setError(null); // Clear any previous errors
      } catch (error) {
        setError('Failed to fetch user details or transactions');
        console.error('Error fetching data:', error);
        setLoading(false);
        setTransactions([]);
      }
    };

    fetchUserAndTransactions();
  }, [userId]);

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }
  
  if (!user) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <span className="block sm:inline">User not found</span>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-800">User Details</h1>
      </div>

      {/* User Information Card */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Personal Information */}
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-primary">Personal Information</h2>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-600">Name</label>
                  <div className="mt-1 text-gray-900">{user.name}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">Surname</label>
                  <div className="mt-1 text-gray-900">{user.surname}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">ID Number</label>
                  <div className="mt-1 text-gray-900">{user.idNumber}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">Mobile Number</label>
                  <div className="mt-1 text-gray-900">{user.mobileNumber}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">Email</label>
                  <div className="mt-1 text-gray-900">{user.email}</div>
                </div>
              </div>
            </div>

            {/* Company Information */}
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-primary">Company Information</h2>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-600">Company</label>
                  <div className="mt-1 text-gray-900">{user.company}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">Additional Info</label>
                  <div className="mt-1 text-gray-900">{user.additionalInfo}</div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-600">Tracking Number</label>
                  <div className="mt-1 text-gray-900">{user.trackingNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* QR Code Card */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-primary mb-4">QR Code</h2>
          <div className="flex justify-center">
            <img
              src={user.qrCode}
              alt={`QR Code for ${user.name}`}
              className="w-48 h-48 object-contain"
            />
          </div>
        </div>
      </div>

      {/* Payment Section */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-primary mb-4">Make Payment</h2>
          <YocoCheckout user={user} onError={setError} />
        </div>
      </div>

      {/* Wallet Balance Section */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-primary">Wallet Balance</h2>
            <div className="text-2xl font-bold text-primary">R {walletBalance.toFixed(2)}</div>
          </div>
          <div className="bg-gray-100 h-2 rounded-full w-full">
            <div 
              className="bg-primary h-2 rounded-full" 
              style={{ width: `${Math.min(walletBalance / 1000 * 100, 100)}%` }}
            ></div>
          </div>
          <div className="mt-2 text-sm text-gray-600">
            {walletBalance > 0 ? 
              `This user has a positive balance of R ${walletBalance.toFixed(2)}` : 
              'This user has no balance yet'}
          </div>
          {user && user.walletAmount !== undefined && (
            <div className="mt-4 p-3 bg-blue-50 rounded text-sm">
              <strong>Note:</strong> User's wallet amount from profile: R {parseFloat(user.walletAmount).toFixed(2)}
            </div>
          )}
        </div>
      </div>
      
      {/* User Transactions Section */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-primary mb-4">User Transactions</h2>
          
          {transactions.length === 0 ? (
            <p className="text-gray-600">No transactions found for this user.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="w-full h-16 border-gray-300 border-b py-8">
                    <th className="text-left pl-4 text-gray-600">ID</th>
                    <th className="text-left text-gray-600">Amount</th>
                    <th className="text-left text-gray-600">Status</th>
                    <th className="text-left text-gray-600">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={transaction._id} className="h-16 border-gray-300 border-b">
                      <td className="pl-4 text-sm text-gray-800">{transaction._id.substring(0, 8)}...</td>
                      <td className="text-sm text-gray-800">R {parseFloat(transaction.amount).toFixed(2)}</td>
                      <td className="text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs ${transaction.status === 'succeeded' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                          {transaction.status}
                        </span>
                      </td>
                      <td className="text-sm text-gray-800">
                        {transaction.createdAt ? new Date(transaction.createdAt).toLocaleDateString() + ' ' + new Date(transaction.createdAt).toLocaleTimeString() : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;