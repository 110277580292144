import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import '../styles/theme.css';

function YocoCheckout({ user, onError }) {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!amount) {
      onError('Please enter an amount');
      return;
    }

    setLoading(true);

    try {
      console.log('Creating checkout for user:', user._id, 'amount:', amount);
      const response = await axios.post(`${API_BASE_URL}/api/create-checkout`, {
        userId: user._id,
        amount: parseFloat(amount)
      });

      console.log('Checkout response:', response.data);
      
      const redirectUrl = response.data.redirectUrl || response.data.url || response.data.checkoutUrl;
      if (redirectUrl) {
        console.log('Redirecting to:', redirectUrl);
        window.location = redirectUrl;
      } else {
        console.error('No redirect URL in response:', response.data);
        throw new Error('No checkout URL received from server');
      }
    } catch (error) {
      console.error('Error creating checkout:', error);
      onError(error.response?.data?.error || 'An error occurred while creating the payment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 max-w-md">
      <div>
        <label htmlFor="amount" className="block text-sm font-medium text-gray-600">
          Amount (R)
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">R</span>
          </div>
          <input
            type="number"
            step="0.01"
            min="0"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="focus:ring-primary focus:border-primary block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-lg"
            placeholder="0.00"
            disabled={loading}
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
      >
        {loading ? (
          <div className="flex items-center">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
            Processing...
          </div>
        ) : (
          'Proceed to Payment'
        )}
      </button>
    </form>
  );
}

export default YocoCheckout;
