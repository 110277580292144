import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/theme.css';
import Logo from './Logo';
import { 
  LayoutDashboard, 
  Users, 
  UserPlus, 
  BarChart4, 
  Settings, 
  LogOut,
  ChevronRight,
  CreditCard,
  User
} from 'lucide-react';

function Layout({ isPublic = false }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isActive = (path) => location.pathname === path;

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  
  if (isPublic) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Outlet />
      </div>
    );
  }

  const menuItems = [
    { path: '/', icon: LayoutDashboard, label: 'Dashboard' },
    { path: '/users', icon: Users, label: 'Users' },
    { path: '/users/add', icon: UserPlus, label: 'Add User' },
    { path: '/transactions', icon: CreditCard, label: 'Transactions' },
    { path: '/reports', icon: BarChart4, label: 'Reports' },
    { path: '/settings', icon: Settings, label: 'Settings' },
  ];

  return (
    <div className="min-h-screen flex bg-gray-50">
      {/* Sidebar */}
      <div className={`${sidebarCollapsed ? 'w-20' : 'w-64'} bg-white shadow-xl fixed h-full transition-all duration-300 ease-in-out z-20`}>
        <div className="flex flex-col h-full">
          {/* Logo and Toggle */}
          <div className="flex items-center justify-between h-20 px-4 bg-primary">
            <div className="flex items-center justify-center w-full">
              <Logo compact={sidebarCollapsed} withBackground={true} />
            </div>
            <button 
              onClick={toggleSidebar} 
              className="absolute -right-4 top-8 bg-white rounded-full p-1 shadow-md hover:shadow-lg transition-all duration-200 z-30"
            >
              <ChevronRight className={`h-5 w-5 text-primary transform transition-transform duration-300 ${sidebarCollapsed ? 'rotate-180' : ''}`} />
            </button>
          </div>

          {/* Navigation Links */}
          <nav className="flex-1 px-2 py-6 space-y-1 overflow-y-auto">
            {menuItems.map((item) => {
              const isItemActive = item.path === '/' ? isActive(item.path) : location.pathname.includes(item.path);
              const Icon = item.icon;
              
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center ${sidebarCollapsed ? 'justify-center' : 'px-4'} py-3 rounded-lg transition-all duration-200 group ${
                    isItemActive
                      ? 'bg-primary text-white shadow-md'
                      : 'text-gray-600 hover:bg-primary-lighter hover:text-white'
                  }`}
                >
                  <Icon className={`${sidebarCollapsed ? 'mx-auto' : 'mr-3'} h-5 w-5 transition-all duration-200 ${isItemActive ? 'stroke-[2.5px]' : 'group-hover:scale-110'}`} />
                  {!sidebarCollapsed && (
                    <span className={`${isItemActive ? 'font-medium' : ''} transition-all duration-200`}>{item.label}</span>
                  )}
                  {sidebarCollapsed && (
                    <div className="absolute left-full ml-2 px-2 py-1 bg-primary-dark text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
                      {item.label}
                    </div>
                  )}
                </Link>
              );
            })}
          </nav>

          {/* Logout Button */}
          <div className="p-4">
            <button
              onClick={handleLogout}
              className={`w-full flex items-center ${sidebarCollapsed ? 'justify-center' : 'px-4'} py-3 rounded-lg text-gray-600 hover:bg-primary hover:text-white transition-all duration-200 group`}
            >
              <LogOut className={`${sidebarCollapsed ? 'mx-auto' : 'mr-3'} h-5 w-5 group-hover:scale-110 transition-all duration-200`} />
              {!sidebarCollapsed && <span>Logout</span>}
              {sidebarCollapsed && (
                <div className="absolute left-full ml-2 px-2 py-1 bg-primary-dark text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap">
                  Logout
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 ${sidebarCollapsed ? 'ml-20' : 'ml-64'} transition-all duration-300 ease-in-out`}>
        {/* Top Bar */}
        <div className="bg-white shadow-md h-16 fixed right-0 left-0 z-10 transition-all duration-300 ease-in-out">
          <div className={`h-full w-full ${sidebarCollapsed ? 'pl-28' : 'pl-72'} pr-8 transition-all duration-300 ease-in-out`}>
            <div className="h-full flex items-center justify-between">
              <div className="flex items-center">
                <div className="bg-primary text-white p-2 rounded-md mr-3 shadow-sm">
                  {menuItems.find(item => 
                    item.path === '/' ? isActive(item.path) : location.pathname.includes(item.path)
                  )?.icon && React.createElement(menuItems.find(item => 
                    item.path === '/' ? isActive(item.path) : location.pathname.includes(item.path)
                  )?.icon, { className: 'h-5 w-5' })}
                </div>
                <h1 className="text-xl font-semibold text-gray-800">
                  {menuItems.find(item => 
                    item.path === '/' ? isActive(item.path) : location.pathname.includes(item.path)
                  )?.label || 'Dashboard'}
                </h1>
              </div>
              {/* User Profile */}
              <div className="flex items-center space-x-3">
                <div className="text-right">
                  <p className="text-sm font-medium text-gray-900">{user?.username}</p>
                  <p className="text-xs text-gray-500">Administrator</p>
                </div>
                <div className="w-10 h-10 rounded-full bg-primary text-white flex items-center justify-center shadow-md hover:shadow-lg transition-all duration-200 cursor-pointer">
                  <User className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Content */}
        <div className="pt-20 p-6 transition-all duration-300 ease-in-out">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
