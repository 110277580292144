import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/theme.css';
import { FaSort, FaSortUp, FaSortDown, FaSearch, FaFilter } from 'react-icons/fa';

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Filtering and sorting state
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');
  const [amountFilter, setAmountFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });

  useEffect(() => {
    const fetchTransactionsAndUsers = async () => {
      try {
        setLoading(true);
        // Fetch transactions
        const transactionsResponse = await axios.get('http://localhost:5001/api/payments');
        console.log('Transactions data:', transactionsResponse.data);
        
        // Check if the response has the new structure or old structure
        if (transactionsResponse.data.payments) {
          // New API structure
          setTransactions(transactionsResponse.data.payments);
          
          // The new API already includes user names in the response
          // We'll still build a users map for backward compatibility
          const usersMap = {};
          transactionsResponse.data.payments.forEach(payment => {
            if (payment.userId && typeof payment.userId === 'object') {
              usersMap[payment.userId._id] = payment.userId;
            }
          });
          setUsers(usersMap);
        } else {
          // Old API structure
          setTransactions(transactionsResponse.data);
          
          // Get unique user IDs from transactions
          const userIds = [...new Set(transactionsResponse.data.map(t => t.userId))];
          
          // Create a map to store user data
          const usersMap = {};
          
          // Fetch user data for each unique user ID
          await Promise.all(userIds.map(async (userId) => {
            try {
              const userResponse = await axios.get(`http://localhost:5001/api/users/${userId}`);
              usersMap[userId] = userResponse.data;
            } catch (error) {
              console.error(`Error fetching user ${userId}:`, error);
              usersMap[userId] = { name: 'Unknown', surname: 'User' };
            }
          }));
          
          setUsers(usersMap);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to load transactions. Please try again later.');
        setLoading(false);
      }
    };

    fetchTransactionsAndUsers();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };
  
  // Sort function
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  // Get sort icon based on current sort state
  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) return <FaSort className="inline ml-1" />;
    return sortConfig.direction === 'asc' ? 
      <FaSortUp className="inline ml-1 text-blue-600" /> : 
      <FaSortDown className="inline ml-1 text-blue-600" />;
  };
  
  // Filter transactions based on all filters
  const filteredTransactions = useMemo(() => {
    // Ensure transactions is an array before filtering
    if (!Array.isArray(transactions)) {
      console.error('Transactions is not an array:', transactions);
      return [];
    }
    return transactions.filter(transaction => {
      // Search term filter (checks ID, amount, and user name)
      let userName = '';
      
      // Handle both old and new API response formats
      if (transaction.userName) {
        // New format - userName is directly in the transaction
        userName = transaction.userName.toLowerCase();
      } else if (typeof transaction.userId === 'object' && transaction.userId) {
        // New format - userId is an object with name and surname
        userName = `${transaction.userId.name || ''} ${transaction.userId.surname || ''}`.toLowerCase();
      } else {
        // Old format - need to look up user in users map
        const user = users[transaction.userId] || {};
        userName = `${user.name || ''} ${user.surname || ''}`.toLowerCase();
      }
      const searchTermMatch = 
        transaction._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userName.includes(searchTerm.toLowerCase()) ||
        transaction.amount.toString().includes(searchTerm.toLowerCase());
      
      // Status filter
      const statusMatch = statusFilter === 'all' || transaction.status === statusFilter;
      
      // Date filter
      let dateMatch = true;
      if (dateFilter !== 'all') {
        const txDate = new Date(transaction.createdAt);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);
        
        const lastMonth = new Date(today);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        
        switch(dateFilter) {
          case 'today':
            dateMatch = txDate.toDateString() === today.toDateString();
            break;
          case 'yesterday':
            dateMatch = txDate.toDateString() === yesterday.toDateString();
            break;
          case 'week':
            dateMatch = txDate >= lastWeek;
            break;
          case 'month':
            dateMatch = txDate >= lastMonth;
            break;
          default:
            dateMatch = true;
        }
      }
      
      // Amount filter
      let amountMatch = true;
      const amount = parseFloat(transaction.amount);
      
      if (amountFilter !== 'all') {
        switch(amountFilter) {
          case 'small': // Less than R50
            amountMatch = amount < 50;
            break;
          case 'medium': // R50 - R200
            amountMatch = amount >= 50 && amount <= 200;
            break;
          case 'large': // More than R200
            amountMatch = amount > 200;
            break;
          default:
            amountMatch = true;
        }
      }
      
      return searchTermMatch && statusMatch && dateMatch && amountMatch;
    });
  }, [transactions, users, searchTerm, statusFilter, dateFilter, amountFilter]);
  
  // Sort filtered transactions
  const sortedTransactions = useMemo(() => {
    const sortableTransactions = [...filteredTransactions];
    sortableTransactions.sort((a, b) => {
      if (sortConfig.key === 'user') {
        let nameA = '';
        let nameB = '';
        
        // Handle both old and new API response formats for user A
        if (a.userName) {
          nameA = a.userName.toLowerCase();
        } else if (typeof a.userId === 'object' && a.userId) {
          nameA = `${a.userId.name || ''} ${a.userId.surname || ''}`.toLowerCase();
        } else {
          const userA = users[a.userId] || { name: '', surname: '' };
          nameA = `${userA.name} ${userA.surname}`.toLowerCase();
        }
        
        // Handle both old and new API response formats for user B
        if (b.userName) {
          nameB = b.userName.toLowerCase();
        } else if (typeof b.userId === 'object' && b.userId) {
          nameB = `${b.userId.name || ''} ${b.userId.surname || ''}`.toLowerCase();
        } else {
          const userB = users[b.userId] || { name: '', surname: '' };
          nameB = `${userB.name} ${userB.surname}`.toLowerCase();
        }
        
        if (nameA < nameB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (nameA > nameB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
      
      if (sortConfig.key === 'amount') {
        const amountA = parseFloat(a.amount);
        const amountB = parseFloat(b.amount);
        return sortConfig.direction === 'asc' ? amountA - amountB : amountB - amountA;
      }
      
      if (sortConfig.key === 'createdAt') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortableTransactions;
  }, [filteredTransactions, sortConfig, users]);

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Transactions</h2>
        <p className="text-gray-600">Loading transactions...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Transactions</h2>
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Transactions</h2>
      
      {/* Search and Filter Controls */}
      <div className="mb-6 space-y-4">
        {/* Search Box */}
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Search by ID, user, or amount..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        {/* Filter Controls */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Status Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
            <div className="relative">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="all">All Statuses</option>
                <option value="succeeded">Succeeded</option>
                <option value="pending">Pending</option>
                <option value="failed">Failed</option>
                <option value="cancelled">Cancelled</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <FaFilter className="text-gray-400" />
              </div>
            </div>
          </div>
          
          {/* Date Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Date Range</label>
            <div className="relative">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <option value="all">All Time</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="week">Last 7 Days</option>
                <option value="month">Last 30 Days</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <FaFilter className="text-gray-400" />
              </div>
            </div>
          </div>
          
          {/* Amount Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Amount</label>
            <div className="relative">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={amountFilter}
                onChange={(e) => setAmountFilter(e.target.value)}
              >
                <option value="all">All Amounts</option>
                <option value="small">Small (less than R50)</option>
                <option value="medium">Medium (R50 - R200)</option>
                <option value="large">Large (more than R200)</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <FaFilter className="text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Results Summary */}
      <div className="mb-4 text-sm text-gray-600">
        Showing {sortedTransactions.length} {transactions.length ? `of ${transactions.length}` : ''} transactions
      </div>
      
      {sortedTransactions.length === 0 ? (
        <p className="text-gray-600">No transactions match your filters.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full h-16 border-gray-300 border-b py-8">
                <th className="text-left pl-4 text-gray-600 cursor-pointer" onClick={() => requestSort('_id')}>
                  ID {getSortIcon('_id')}
                </th>
                <th className="text-left text-gray-600 cursor-pointer" onClick={() => requestSort('user')}>
                  User {getSortIcon('user')}
                </th>
                <th className="text-left text-gray-600 cursor-pointer" onClick={() => requestSort('amount')}>
                  Amount {getSortIcon('amount')}
                </th>
                <th className="text-left text-gray-600 cursor-pointer" onClick={() => requestSort('status')}>
                  Status {getSortIcon('status')}
                </th>
                <th className="text-left text-gray-600 cursor-pointer" onClick={() => requestSort('createdAt')}>
                  Date {getSortIcon('createdAt')}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedTransactions.map((transaction) => (
                <tr key={transaction._id} className="h-16 border-gray-300 border-b">
                  <td className="pl-4 text-sm text-gray-800">{transaction._id.substring(0, 8)}...</td>
                  <td className="text-sm text-gray-800">
                    <Link 
                      to={`/users/${typeof transaction.userId === 'object' ? transaction.userId._id : transaction.userId}`} 
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      {transaction.userName ? transaction.userName : 
                       typeof transaction.userId === 'object' && transaction.userId ? 
                        `${transaction.userId.name} ${transaction.userId.surname}` :
                        users[transaction.userId] ? 
                          `${users[transaction.userId].name} ${users[transaction.userId].surname}` : 
                          `${String(transaction.userId).substring(0, 8)}...`}
                    </Link>
                  </td>
                  <td className="text-sm text-gray-800">R {parseFloat(transaction.amount).toFixed(2)}</td>
                  <td className="text-sm">
                    <span className={`px-2 py-1 rounded-full text-xs ${transaction.status === 'succeeded' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                      {transaction.status}
                    </span>
                  </td>
                  <td className="text-sm text-gray-800">{transaction.createdAt ? formatDate(transaction.createdAt) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Transactions;
