import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function AdminUsersList({ limit = 10, compact = false }) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [companyFilter, setCompanyFilter] = useState('');
  const [companies, setCompanies] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  // Use useCallback to memoize the fetchUsers function
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/users`, {
        params: {
          page: currentPage,
          limit: limit,
          search: searchTerm,
          company: companyFilter,
          sortBy: sortField,
          sortOrder
        }
      });
      
      console.log('Users API response:', response.data);
      
      // Handle different response formats
      let userData = [];
      if (response.data && response.data.users) {
        // New API format with pagination
        userData = response.data.users;
        setTotalPages(response.data.totalPages || 1);
      } else if (Array.isArray(response.data)) {
        // Old API format (direct array)
        userData = response.data;
        setTotalPages(1);
      } else {
        console.error('Unexpected API response format:', response.data);
        userData = [];
        setTotalPages(1);
      }
      
      // Extract unique companies for filtering
      const uniqueCompanies = new Set();
      userData.forEach(user => {
        if (user.company) {
          uniqueCompanies.add(user.company);
        }
      });
      setCompanies(uniqueCompanies);
      
      setUsers(userData);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again.');
      setUsers([]);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm, companyFilter, sortField, sortOrder, limit]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const getSortIcon = () => {
    if (sortOrder === 'asc') {
      return <i className="fas fa-sort-up"></i>;
    } else {
      return <i className="fas fa-sort-down"></i>;
    }
  };

  const exportUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      const users = response.data.users || response.data;

      // Create worksheet with all fields
      const ws = XLSX.utils.json_to_sheet(users.map(user => ({
        'Name': user.name || '',
        'Surname': user.surname || '',
        'ID Number': user.idNumber || '',
        'Mobile Number': user.mobileNumber || '',
        'Email': user.email || '',
        'Company': user.company || '',
        'Additional Info': user.additionalInfo || '',
        'Tracking Number': user.trackingNumber || '',
        'Wallet Amount': user.walletAmount ? `R ${user.walletAmount.toFixed(2)}` : 'N/A'
      })));

      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Users');

      // Generate Excel file
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Save file with current date
      const date = new Date().toISOString().split('T')[0];
      saveAs(data, `users_${date}.xlsx`);
    } catch (error) {
      console.error('Error exporting users:', error);
      setError('Failed to export users. Please try again.');
    }
  };

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(startIndex + 9, users.length);

  return (
    <div className={`${compact ? '' : 'space-y-6'}`}>
      {/* Header - Only show if not compact */}
      {!compact && (
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-800">Users Management</h1>
          <button
            onClick={exportUsers}
            className="btn btn-outline-primary rounded-lg px-4 py-2 flex items-center"
          >
            <i className="fas fa-download mr-2"></i>
            Export to Excel
          </button>
        </div>
      )}

      {/* Search and Filter - Only show if not compact */}
      {!compact && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-box w-full"
            />
            <i className="fas fa-search absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
          </div>
          
          <select
            value={companyFilter}
            onChange={(e) => setCompanyFilter(e.target.value)}
            className="form-select rounded-lg border-gray-300 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
          >
            <option value="">All Companies</option>
            {[...companies].map((company) => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Users Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th onClick={() => handleSort('name')} className="cursor-pointer">
                  Name {sortField === 'name' && getSortIcon()}
                </th>
                <th onClick={() => handleSort('surname')} className="cursor-pointer">
                  Surname {sortField === 'surname' && getSortIcon()}
                </th>
                <th onClick={() => handleSort('email')} className="cursor-pointer">
                  Email {sortField === 'email' && getSortIcon()}
                </th>
                <th onClick={() => handleSort('company')} className="cursor-pointer">
                  Company {sortField === 'company' && getSortIcon()}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center py-4">Loading users...</td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="5" className="text-center py-4 text-red-500">{error}</td>
                </tr>
              ) : users.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center py-4">No users found</td>
                </tr>
              ) : users.map((user) => (
                <tr key={user._id} className="hover:bg-gray-50">
                  <td className="whitespace-nowrap">{user.name}</td>
                  <td className="whitespace-nowrap">{user.surname}</td>
                  <td className="whitespace-nowrap">{user.email}</td>
                  <td className="whitespace-nowrap">{user.company}</td>
                  <td className="whitespace-nowrap">
                    <Link
                      to={`/users/${user._id}`}
                      className="btn btn-primary btn-sm rounded-lg"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination - Only show if not compact */}
      {!compact && (
        <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-600">
            Showing {startIndex} to {endIndex} of {users.length} users
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="btn btn-outline-primary rounded-lg px-4 py-2 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="btn btn-outline-primary rounded-lg px-4 py-2 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
    </div>
  );
}

export default AdminUsersList;