import React from 'react';
import '../styles/theme.css';

function Reports() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Reports</h2>
      <p className="text-gray-600">Reports and analytics will be displayed here.</p>
    </div>
  );
}

export default Reports;
